/* You can add global styles to this file, and also import other style files */
@import './sass/abstracts/variables';
@import './sass/abstracts/mixins';

html,
body {
  width: 100%;
  font-family:  $font-stack;
  font-size: 18px;
  letter-spacing: 1px;
  color: $font-color;
  margin: 0;
  padding: 0;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: font-size 200ms;

  @media screen and (max-width: $break-large) {
    font-size: 17px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 16px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 17px;
  }
}

body * {
  outline: none;
}

h2 {
  text-transform: uppercase;
  border-bottom: 4px solid $picton;
  text-align: center;
  font-size: 150%;

  @media screen and (max-width: $break-small) {
    font-size: 130%;
  }
}

$button-nav-radius: 3px;

.btn-content-nav {
  max-width: 300px;
  height: 30px;
  padding: 0;
  margin: 0;
  box-shadow: 2px 5px 5px $dark-shadow;
  color: $font-color;
  font-size: 110%;
  display: grid;
  grid-template-columns: 50px auto;
  justify-items: center;
  align-items: center;

  .icon {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $picton;
    border-top-left-radius: $button-nav-radius;
    border-bottom-left-radius: $button-nav-radius;
    box-shadow: 2px 0 10px 1px $dark-shadow;
    z-index: 50;
  }

  .text {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    border-top-right-radius: $button-nav-radius;
    border-bottom-right-radius: $button-nav-radius;

    p {
      padding: 0 1rem;
      margin: 0;
    }
  }
}

.invisible {
  opacity: 0;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

@keyframes blink {
  0% { opacity: 1; }
  40% { opacity: 1; }
  50% { opacity: 0; }
  60% { opacity: 0; }
  70% { opacity: 1; }
  100% { opacity: 1; }
}